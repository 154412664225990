namespace $ {
	export class $hyoo_speculant_app_news extends $mol_list {
		
		/**
		 * ```tree
		 * model $hyoo_speculant_world
		 * ```
		 */
		@ $mol_mem
		model() {
			const obj = new this.$.$hyoo_speculant_world()
			
			return obj
		}
		
		/**
		 * ```tree
		 * unread_count 0
		 * ```
		 */
		unread_count() {
			return 0
		}
		
		/**
		 * ```tree
		 * News_item* $hyoo_speculant_app_news_item
		 * 	news_item <= news_item*
		 * 	news_read?val <=> news_read*?val
		 * ```
		 */
		@ $mol_mem_key
		News_item(id: any) {
			const obj = new this.$.$hyoo_speculant_app_news_item()
			
			obj.news_item = () => this.news_item(id)
			obj.news_read = (val?: any) => this.news_read(id, val)
			
			return obj
		}
		
		/**
		 * ```tree
		 * rows / <= List
		 * ```
		 */
		rows() {
			return [
				this.List()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * news_item* \
		 * ```
		 */
		news_item(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * news_read*?val false
		 * ```
		 */
		@ $mol_mem_key
		news_read(id: any, val?: any) {
			if ( val !== undefined ) return val as never
			return false
		}
		
		/**
		 * ```tree
		 * news_list /
		 * ```
		 */
		news_list() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * List $mol_list rows <= news_list
		 * ```
		 */
		@ $mol_mem
		List() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.news_list()
			
			return obj
		}
	}
	
	export class $hyoo_speculant_app_news_item extends $mol_list {
		
		/**
		 * ```tree
		 * news_item \
		 * ```
		 */
		news_item() {
			return ""
		}
		
		/**
		 * ```tree
		 * news_read?val false
		 * ```
		 */
		@ $mol_mem
		news_read(val?: any) {
			if ( val !== undefined ) return val as never
			return false
		}
		
		/**
		 * ```tree
		 * rows /
		 * 	<= Date
		 * 	<= News_item_title
		 * ```
		 */
		rows() {
			return [
				this.Date(),
				this.News_item_title()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * date \
		 * ```
		 */
		date() {
			return ""
		}
		
		/**
		 * ```tree
		 * Date $mol_view sub / <= date
		 * ```
		 */
		@ $mol_mem
		Date() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.date()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * text \
		 * ```
		 */
		text() {
			return ""
		}
		
		/**
		 * ```tree
		 * News_item_title $mol_text text <= text
		 * ```
		 */
		@ $mol_mem
		News_item_title() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.text()
			
			return obj
		}
	}
	
}

