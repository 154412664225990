namespace $ {
	export class $mol_icon_arrow_down extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M11,4H13V16L18.5,10.5L19.92,11.92L12,19.84L4.08,11.92L5.5,10.5L11,16V4Z
		 * ```
		 */
		path() {
			return "M11,4H13V16L18.5,10.5L19.92,11.92L12,19.84L4.08,11.92L5.5,10.5L11,16V4Z"
		}
	}
	
}

