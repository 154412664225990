namespace $ {
	export class $hyoo_speculant_app extends $mol_book2 {
		
		/**
		 * ```tree
		 * model?val $hyoo_speculant_world
		 * ```
		 */
		@ $mol_mem
		model(val?: any) {
			if ( val !== undefined ) return val as never
			const obj = new this.$.$hyoo_speculant_world()
			
			return obj
		}
		
		/**
		 * ```tree
		 * balance_total \
		 * ```
		 */
		balance_total() {
			return ""
		}
		
		/**
		 * ```tree
		 * page_tools /
		 * 	<= Source
		 * 	<= Lights
		 * 	<= Chat
		 * ```
		 */
		page_tools() {
			return [
				this.Source(),
				this.Lights(),
				this.Chat()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * plugins / <= Theme
		 * ```
		 */
		plugins() {
			return [
				this.Theme()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Page_dashboard $hyoo_speculant_app_dashboard
		 * 	model <= model
		 * 	tools <= page_tools
		 * ```
		 */
		@ $mol_mem
		Page_dashboard() {
			const obj = new this.$.$hyoo_speculant_app_dashboard()
			
			obj.model = () => this.model()
			obj.tools = () => this.page_tools()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Page_profile $mol_page
		 * 	title \СпекулянтЪ
		 * 	tools <= page_tools
		 * 	body / <= Page_profile_content
		 * ```
		 */
		@ $mol_mem
		Page_profile() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "СпекулянтЪ"
			obj.tools = () => this.page_tools()
			obj.body = () => [
				this.Page_profile_content()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Profile_button* $mol_button_major
		 * 	title <= profile_title*
		 * 	click?val <=> profile_select*?val
		 * ```
		 */
		@ $mol_mem_key
		Profile_button(id: any) {
			const obj = new this.$.$mol_button_major()
			
			obj.title = () => this.profile_title(id)
			obj.click = (val?: any) => this.profile_select(id, val)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Portfolio_item* $mol_labeler
		 * 	title <= portfolio_title*
		 * 	content / <= portfolio_have*
		 * ```
		 */
		@ $mol_mem_key
		Portfolio_item(id: any) {
			const obj = new this.$.$mol_labeler()
			
			obj.title = () => this.portfolio_title(id)
			obj.content = () => [
				this.portfolio_have(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Balance_total $mol_labeler
		 * 	title <= balance_total_title
		 * 	content / <= balance_total
		 * ```
		 */
		@ $mol_mem
		Balance_total() {
			const obj = new this.$.$mol_labeler()
			
			obj.title = () => this.balance_total_title()
			obj.content = () => [
				this.balance_total()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Cash_total $mol_labeler
		 * 	title \Кэш
		 * 	content / <= cash_total
		 * ```
		 */
		@ $mol_mem
		Cash_total() {
			const obj = new this.$.$mol_labeler()
			
			obj.title = () => "Кэш"
			obj.content = () => [
				this.cash_total()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Page_final $mol_page
		 * 	title \СпекулянтЪ
		 * 	tools <= page_tools
		 * 	body / <= Page_final_content
		 * 	foot /
		 * 		<= Share
		 * 		<= Restart
		 * ```
		 */
		@ $mol_mem
		Page_final() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "СпекулянтЪ"
			obj.tools = () => this.page_tools()
			obj.body = () => [
				this.Page_final_content()
			] as readonly any[]
			obj.foot = () => [
				this.Share(),
				this.Restart()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Source $mol_link_source uri \https://github.com/hyoo-ru/speculant.hyoo.ru
		 * ```
		 */
		@ $mol_mem
		Source() {
			const obj = new this.$.$mol_link_source()
			
			obj.uri = () => "https://github.com/hyoo-ru/speculant.hyoo.ru"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Lights $mol_lights_toggle
		 * ```
		 */
		@ $mol_mem
		Lights() {
			const obj = new this.$.$mol_lights_toggle()
			
			return obj
		}
		
		/**
		 * ```tree
		 * chat_pages
		 * ```
		 */
		chat_pages() {
			return this.Chat().pages()
		}
		
		/**
		 * ```tree
		 * Chat $mol_chat pages => chat_pages
		 * ```
		 */
		@ $mol_mem
		Chat() {
			const obj = new this.$.$mol_chat()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Theme $mol_theme_auto
		 * ```
		 */
		@ $mol_mem
		Theme() {
			const obj = new this.$.$mol_theme_auto()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Description $mol_text text \
		 * 	\Успешный инвестор держит руку на пульсе новостей.
		 * 	\
		 * 	\В вашем раcпоряжении **1 игровой год**, который пролетит за **считанные минуты**.
		 * 	\
		 * 	\Следите за новостями и **заработайте миллион**, правильно инвестируя свой капитал.
		 * 	\
		 * 	\Но сперва быстрое знакомство..
		 * ```
		 */
		@ $mol_mem
		Description() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => "Успешный инвестор держит руку на пульсе новостей.\n\nВ вашем раcпоряжении **1 игровой год**, который пролетит за **считанные минуты**.\n\nСледите за новостями и **заработайте миллион**, правильно инвестируя свой капитал.\n\nНо сперва быстрое знакомство.."
			
			return obj
		}
		
		/**
		 * ```tree
		 * name_bid \
		 * ```
		 */
		name_bid() {
			return ""
		}
		
		/**
		 * ```tree
		 * name?val \
		 * ```
		 */
		@ $mol_mem
		name(val?: any) {
			if ( val !== undefined ) return val as never
			return ""
		}
		
		/**
		 * ```tree
		 * Name_control $mol_string
		 * 	hint \Иванов
		 * 	value?val <=> name?val
		 * ```
		 */
		@ $mol_mem
		Name_control() {
			const obj = new this.$.$mol_string()
			
			obj.hint = () => "Иванов"
			obj.value = (val?: any) => this.name(val)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name $mol_form_field
		 * 	name \Как вас зовут?
		 * 	bid <= name_bid
		 * 	control <= Name_control
		 * ```
		 */
		@ $mol_mem
		Name() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Как вас зовут?"
			obj.bid = () => this.name_bid()
			obj.control = () => this.Name_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * profile_bid \
		 * ```
		 */
		profile_bid() {
			return ""
		}
		
		/**
		 * ```tree
		 * select_profile?val null
		 * ```
		 */
		@ $mol_mem
		select_profile(val?: any) {
			if ( val !== undefined ) return val as never
			return null as any
		}
		
		/**
		 * ```tree
		 * profile_dict *
		 * ```
		 */
		profile_dict() {
			return {
			}
		}
		
		/**
		 * ```tree
		 * Profile_control $mol_switch
		 * 	value?val <=> select_profile?val
		 * 	options <= profile_dict
		 * ```
		 */
		@ $mol_mem
		Profile_control() {
			const obj = new this.$.$mol_switch()
			
			obj.value = (val?: any) => this.select_profile(val)
			obj.options = () => this.profile_dict()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Profile $mol_form_field
		 * 	name \Род деятельности
		 * 	bid <= profile_bid
		 * 	control <= Profile_control
		 * ```
		 */
		@ $mol_mem
		Profile() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Род деятельности"
			obj.bid = () => this.profile_bid()
			obj.control = () => this.Profile_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * start?val null
		 * ```
		 */
		@ $mol_mem
		start(val?: any) {
			if ( val !== undefined ) return val as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Start $mol_button_major
		 * 	title \Начать
		 * 	click?val <=> start?val
		 * ```
		 */
		@ $mol_mem
		Start() {
			const obj = new this.$.$mol_button_major()
			
			obj.title = () => "Начать"
			obj.click = (val?: any) => this.start(val)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Form $mol_form
		 * 	form_fields /
		 * 		<= Name
		 * 		<= Profile
		 * 	buttons / <= Start
		 * ```
		 */
		@ $mol_mem
		Form() {
			const obj = new this.$.$mol_form()
			
			obj.form_fields = () => [
				this.Name(),
				this.Profile()
			] as readonly any[]
			obj.buttons = () => [
				this.Start()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Page_profile_content $mol_list rows /
		 * 	<= Description
		 * 	<= Form
		 * ```
		 */
		@ $mol_mem
		Page_profile_content() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Description(),
				this.Form()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * profile_title* \
		 * ```
		 */
		profile_title(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * profile_select*?val null
		 * ```
		 */
		@ $mol_mem_key
		profile_select(id: any, val?: any) {
			if ( val !== undefined ) return val as never
			return null as any
		}
		
		/**
		 * ```tree
		 * portfolio_title* \
		 * ```
		 */
		portfolio_title(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * portfolio_have* \
		 * ```
		 */
		portfolio_have(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * balance_total_title \Итоговый баланс
		 * ```
		 */
		balance_total_title() {
			return "Итоговый баланс"
		}
		
		/**
		 * ```tree
		 * cash_total \
		 * ```
		 */
		cash_total() {
			return ""
		}
		
		/**
		 * ```tree
		 * final_text \ 
		 * 	\# Ого, {balance} за год!
		 * ```
		 */
		final_text() {
			return "# Ого, {balance} за год!"
		}
		
		/**
		 * ```tree
		 * Final_text $mol_text text <= final_text
		 * ```
		 */
		@ $mol_mem
		Final_text() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.final_text()
			
			return obj
		}
		
		/**
		 * ```tree
		 * portfolio /
		 * ```
		 */
		portfolio() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Portfolio $mol_row sub <= portfolio
		 * ```
		 */
		@ $mol_mem
		Portfolio() {
			const obj = new this.$.$mol_row()
			
			obj.sub = () => this.portfolio()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Page_final_content $mol_list rows /
		 * 	<= Final_text
		 * 	<= Portfolio
		 * ```
		 */
		@ $mol_mem
		Page_final_content() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Final_text(),
				this.Portfolio()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * share_uri \
		 * ```
		 */
		share_uri() {
			return ""
		}
		
		/**
		 * ```tree
		 * Share $mol_button_share
		 * 	uri <= share_uri
		 * 	title \https://speculant.hyoo.ru/
		 * 	hint \Поделиться
		 * ```
		 */
		@ $mol_mem
		Share() {
			const obj = new this.$.$mol_button_share()
			
			obj.uri = () => this.share_uri()
			obj.title = () => "https://speculant.hyoo.ru/"
			obj.hint = () => "Поделиться"
			
			return obj
		}
		
		/**
		 * ```tree
		 * restart?val null
		 * ```
		 */
		@ $mol_mem
		restart(val?: any) {
			if ( val !== undefined ) return val as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Restart_icon $mol_icon_restart
		 * ```
		 */
		@ $mol_mem
		Restart_icon() {
			const obj = new this.$.$mol_icon_restart()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Restart $mol_button_typed
		 * 	click?val <=> restart?val
		 * 	hint \Рестарт
		 * 	sub / <= Restart_icon
		 * ```
		 */
		@ $mol_mem
		Restart() {
			const obj = new this.$.$mol_button_typed()
			
			obj.click = (val?: any) => this.restart(val)
			obj.hint = () => "Рестарт"
			obj.sub = () => [
				this.Restart_icon()
			] as readonly any[]
			
			return obj
		}
	}
	
}

