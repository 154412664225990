namespace $.$$ {
	
	const { rem } = $mol_style_unit

	$mol_style_define( $hyoo_speculant_app_news_item , {
		
		padding: $mol_gap.block,

		Date: {
			color: $mol_theme.shade,
			padding: [ 0, rem(.75) ],
		} ,

	} )

}
