namespace $ {
	export class $mol_icon_arrow_up_bold extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M15,20H9V12H4.16L12,4.16L19.84,12H15V20Z
		 * ```
		 */
		path() {
			return "M15,20H9V12H4.16L12,4.16L19.84,12H15V20Z"
		}
	}
	
}

