namespace $ {
	export class $hyoo_speculant_world extends $mol_object2 {
		
		/**
		 * ```tree
		 * time $mol_time_moment
		 * ```
		 */
		@ $mol_mem
		time() {
			const obj = new this.$.$mol_time_moment()
			
			return obj
		}
		
		/**
		 * ```tree
		 * time_end $mol_time_moment
		 * ```
		 */
		@ $mol_mem
		time_end() {
			const obj = new this.$.$mol_time_moment()
			
			return obj
		}
		
		/**
		 * ```tree
		 * profile?next \other
		 * ```
		 */
		@ $mol_mem
		profile(next?: any) {
			if ( next !== undefined ) return next as never
			return "other"
		}
		
		/**
		 * ```tree
		 * profiles *
		 * 	scholar *
		 * 		title \Учусь
		 * 		indicators /string
		 * 			\KBK
		 * 			\INS
		 * 			\LKN
		 * 			\TTK
		 * 	mature *
		 * 		title \Работаю
		 * 		indicators /string
		 * 			\KBK
		 * 			\TLT
		 * 			\LKN
		 * 			\VBL
		 * 	pensioner *
		 * 		title \Пенсионер
		 * 		indicators /string
		 * 			\KBK
		 * 			\CHK
		 * 			\LKN
		 * 			\OGR
		 * ```
		 */
		profiles() {
			return {
				scholar: {
					title: "Учусь",
					indicators: [
						"KBK",
						"INS",
						"LKN",
						"TTK"
					] as readonly string[]
				},
				mature: {
					title: "Работаю",
					indicators: [
						"KBK",
						"TLT",
						"LKN",
						"VBL"
					] as readonly string[]
				},
				pensioner: {
					title: "Пенсионер",
					indicators: [
						"KBK",
						"CHK",
						"LKN",
						"OGR"
					] as readonly string[]
				}
			}
		}
		
		/**
		 * ```tree
		 * scoring \care
		 * ```
		 */
		scoring() {
			return "care"
		}
		
		/**
		 * ```tree
		 * exchange*?diff null
		 * ```
		 */
		@ $mol_mem_key
		exchange(id: any, diff?: any) {
			if ( diff !== undefined ) return diff as never
			return null as any
		}
		
		/**
		 * ```tree
		 * indicators?next *
		 * 	CSH *
		 * 		name \Кэш
		 * 		icon \about:blank
		 * 		type \currency
		 * 		have 1000
		 * 		current 1
		 * 		diff 0
		 * 		trend 0
		 * 		history /number
		 * 	KBK *
		 * 		name \ДалаемФлекс
		 * 		icon \https://api.faviconkit.com/netflix.com/16
		 * 		type \share
		 * 		have 0
		 * 		current 100
		 * 		diff 0
		 * 		trend 0
		 * 		history /number
		 * 	TLT *
		 * 		name \Телетайп
		 * 		icon \https://api.faviconkit.com/telegram.org/16
		 * 		type \bond
		 * 		have 0
		 * 		current 200
		 * 		diff 0
		 * 		trend 0
		 * 		history /number
		 * 	CHK *
		 * 		name \ЧоКак
		 * 		icon \https://api.faviconkit.com/whatsapp.com/16
		 * 		type \bond
		 * 		have 0
		 * 		current 100
		 * 		diff 0
		 * 		trend 0
		 * 		history /number
		 * 	LKN *
		 * 		name \ЛицеКнига
		 * 		icon \https://api.faviconkit.com/facebook.com/16
		 * 		type \share
		 * 		have 0
		 * 		current 50
		 * 		diff 0
		 * 		trend 0
		 * 		history /number
		 * 	TTK *
		 * 		name \ТукТок
		 * 		icon \https://api.faviconkit.com/tiktok.com/16
		 * 		type \share
		 * 		have 0
		 * 		current 60
		 * 		diff 0
		 * 		trend 0
		 * 		history /number
		 * 	INS *
		 * 		name \ИнтерСпам
		 * 		icon \https://api.faviconkit.com/instagram.com/16
		 * 		type \share
		 * 		have 0
		 * 		current 50
		 * 		diff 0
		 * 		trend 0
		 * 		history /number
		 * 	VBL *
		 * 		name \ВБлизи
		 * 		icon \https://api.faviconkit.com/vk.com/16
		 * 		type \share
		 * 		have 0
		 * 		current 30
		 * 		diff 0
		 * 		trend 0
		 * 		history /number
		 * 	OGR *
		 * 		name \Однокашники
		 * 		icon \https://api.faviconkit.com/ok.ru/16
		 * 		type \share
		 * 		have 0
		 * 		current 20
		 * 		diff 0
		 * 		trend 0
		 * 		history /number
		 * ```
		 */
		@ $mol_mem
		indicators(next?: any) {
			if ( next !== undefined ) return next as never
			return {
				CSH: {
					name: "Кэш",
					icon: "about:blank",
					type: "currency",
					have: 1000,
					current: 1,
					diff: 0,
					trend: 0,
					history: [
					] as readonly number[]
				},
				KBK: {
					name: "ДалаемФлекс",
					icon: "https://api.faviconkit.com/netflix.com/16",
					type: "share",
					have: 0,
					current: 100,
					diff: 0,
					trend: 0,
					history: [
					] as readonly number[]
				},
				TLT: {
					name: "Телетайп",
					icon: "https://api.faviconkit.com/telegram.org/16",
					type: "bond",
					have: 0,
					current: 200,
					diff: 0,
					trend: 0,
					history: [
					] as readonly number[]
				},
				CHK: {
					name: "ЧоКак",
					icon: "https://api.faviconkit.com/whatsapp.com/16",
					type: "bond",
					have: 0,
					current: 100,
					diff: 0,
					trend: 0,
					history: [
					] as readonly number[]
				},
				LKN: {
					name: "ЛицеКнига",
					icon: "https://api.faviconkit.com/facebook.com/16",
					type: "share",
					have: 0,
					current: 50,
					diff: 0,
					trend: 0,
					history: [
					] as readonly number[]
				},
				TTK: {
					name: "ТукТок",
					icon: "https://api.faviconkit.com/tiktok.com/16",
					type: "share",
					have: 0,
					current: 60,
					diff: 0,
					trend: 0,
					history: [
					] as readonly number[]
				},
				INS: {
					name: "ИнтерСпам",
					icon: "https://api.faviconkit.com/instagram.com/16",
					type: "share",
					have: 0,
					current: 50,
					diff: 0,
					trend: 0,
					history: [
					] as readonly number[]
				},
				VBL: {
					name: "ВБлизи",
					icon: "https://api.faviconkit.com/vk.com/16",
					type: "share",
					have: 0,
					current: 30,
					diff: 0,
					trend: 0,
					history: [
					] as readonly number[]
				},
				OGR: {
					name: "Однокашники",
					icon: "https://api.faviconkit.com/ok.ru/16",
					type: "share",
					have: 0,
					current: 20,
					diff: 0,
					trend: 0,
					history: [
					] as readonly number[]
				}
			}
		}
		
		/**
		 * ```tree
		 * entropy *
		 * 	bond 1
		 * 	currency 5
		 * 	share 10
		 * ```
		 */
		entropy() {
			return {
				bond: 1,
				currency: 5,
				share: 10
			}
		}
		
		/**
		 * ```tree
		 * age?next \ready
		 * ```
		 */
		@ $mol_mem
		age(next?: any) {
			if ( next !== undefined ) return next as never
			return "ready"
		}
		
		/**
		 * ```tree
		 * news / *
		 * 	text \
		 * 		\## Новый профи вышел на рынок
		 * 		\Все спекулянты замерли в ожидании.
		 * 	moment \
		 * ```
		 */
		news() {
			return [
				{
					text: "## Новый профи вышел на рынок\nВсе спекулянты замерли в ожидании.",
					moment: ""
				}
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * news_templates /
		 * 	*
		 * 		trend -10
		 * 		text \
		 * 			\## ВедроСоюз ввёл санкции против Московии
		 * 			\**{name}** уже объявил об уходе с рынка.
		 * 	*
		 * 		trend -5
		 * 		text \
		 * 			\## Утечка персональных данных
		 * 			\Хорошо, что в **{name}** нет на вас досье.
		 * 	*
		 * 		trend -2
		 * 		text \
		 * 			\## Массовая дискриминация боевых вертолётов
		 * 			\Борцы за социальную справедливость ревут в **{name}**
		 * 	*
		 * 		trend -1
		 * 		text \
		 * 			\## Сайт **{name}** не отвечает
		 * 			\Производительность труда выросла в два раза.
		 * 	*
		 * 		trend -1
		 * 		text \
		 * 			\## РосЮсбНаблюд заблокировал **{name}**
		 * 			\Ваш любимый сайт теперь открывается через раз.
		 * 	*
		 * 		trend 0
		 * 		text \
		 * 			\## Московия вступила в ЗНАТО
		 * 			\Интернет группа Ананасус взяла ответственность на себя.
		 * 	*
		 * 		trend 0
		 * 		text \
		 * 			\## Родился бурый медвежонок альбинос
		 * 			\Наблюдаются огромные очереди в центральный зоопарк.
		 * 	*
		 * 		trend 1
		 * 		text \
		 * 			\## Новый отчёт финансового аналитика
		 * 			\Ничего интересного про **{name}** в нём нет.
		 * 	*
		 * 		trend 5
		 * 		text \
		 * 			\## Айрон Маркс упомянул про **{name}**
		 * 			\Биржы еле справляются с начавшимся ажиотажем.
		 * 	*
		 * 		trend 10
		 * 		text \
		 * 			\## Клавиатурные революционеры вторглись в Московию
		 * 			\Военные Силы Московии координируют действия теперь через **{name}**
		 * ```
		 */
		news_templates() {
			return [
				{
					trend: -10,
					text: "## ВедроСоюз ввёл санкции против Московии\n**{name}** уже объявил об уходе с рынка."
				},
				{
					trend: -5,
					text: "## Утечка персональных данных\nХорошо, что в **{name}** нет на вас досье."
				},
				{
					trend: -2,
					text: "## Массовая дискриминация боевых вертолётов\nБорцы за социальную справедливость ревут в **{name}**"
				},
				{
					trend: -1,
					text: "## Сайт **{name}** не отвечает\nПроизводительность труда выросла в два раза."
				},
				{
					trend: -1,
					text: "## РосЮсбНаблюд заблокировал **{name}**\nВаш любимый сайт теперь открывается через раз."
				},
				{
					trend: 0,
					text: "## Московия вступила в ЗНАТО\nИнтернет группа Ананасус взяла ответственность на себя."
				},
				{
					trend: 0,
					text: "## Родился бурый медвежонок альбинос\nНаблюдаются огромные очереди в центральный зоопарк."
				},
				{
					trend: 1,
					text: "## Новый отчёт финансового аналитика\nНичего интересного про **{name}** в нём нет."
				},
				{
					trend: 5,
					text: "## Айрон Маркс упомянул про **{name}**\nБиржы еле справляются с начавшимся ажиотажем."
				},
				{
					trend: 10,
					text: "## Клавиатурные революционеры вторглись в Московию\nВоенные Силы Московии координируют действия теперь через **{name}**"
				}
			] as readonly any[]
		}
	}
	
}

