namespace $ {
	export class $mol_icon_arrow_up extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z
		 * ```
		 */
		path() {
			return "M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z"
		}
	}
	
}

