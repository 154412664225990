namespace $ {
	export class $mol_icon_arrow_down_bold extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M9,4H15V12H19.84L12,19.84L4.16,12H9V4Z
		 * ```
		 */
		path() {
			return "M9,4H15V12H19.84L12,19.84L4.16,12H9V4Z"
		}
	}
	
}

