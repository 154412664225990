namespace $ {
	export class $hyoo_speculant_app_dashboard extends $mol_page {
		
		/**
		 * ```tree
		 * model $hyoo_speculant_world
		 * ```
		 */
		@ $mol_mem
		model() {
			const obj = new this.$.$hyoo_speculant_world()
			
			return obj
		}
		
		/**
		 * ```tree
		 * title \Последние Новости
		 * ```
		 */
		title() {
			return "Последние Новости"
		}
		
		/**
		 * ```tree
		 * currency_all /
		 * ```
		 */
		currency_all() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Buy_button* $mol_button_typed
		 * 	hint \Купить
		 * 	click?val <=> buy*?val
		 * 	sub / <= Buy_button_icon*
		 * ```
		 */
		@ $mol_mem_key
		Buy_button(id: any) {
			const obj = new this.$.$mol_button_typed()
			
			obj.hint = () => "Купить"
			obj.click = (val?: any) => this.buy(id, val)
			obj.sub = () => [
				this.Buy_button_icon(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Sell_button* $mol_button_typed
		 * 	hint \Продать
		 * 	click?val <=> sell*?val
		 * 	sub / <= Sell_button_icon*
		 * ```
		 */
		@ $mol_mem_key
		Sell_button(id: any) {
			const obj = new this.$.$mol_button_typed()
			
			obj.hint = () => "Продать"
			obj.click = (val?: any) => this.sell(id, val)
			obj.sub = () => [
				this.Sell_button_icon(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Rate* $mol_view sub /
		 * 	<= rate*
		 * 	<= Currency_diff*
		 * ```
		 */
		@ $mol_mem_key
		Rate(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.rate(id),
				this.Currency_diff(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Icon_currency_up* $mol_icon_arrow_up_bold
		 * ```
		 */
		@ $mol_mem_key
		Icon_currency_up(id: any) {
			const obj = new this.$.$mol_icon_arrow_up_bold()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Icon_currency_down* $mol_icon_arrow_down_bold
		 * ```
		 */
		@ $mol_mem_key
		Icon_currency_down(id: any) {
			const obj = new this.$.$mol_icon_arrow_down_bold()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Balance_currency* $mol_view sub / <= currency_have*
		 * ```
		 */
		@ $mol_mem_key
		Balance_currency(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.currency_have(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Row* $mol_view sub /
		 * 	<= Currency_name*
		 * 	<= Rate*
		 * 	<= Sell_button*
		 * 	<= Balance_currency*
		 * 	<= Buy_button*
		 * ```
		 */
		@ $mol_mem_key
		Row(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Currency_name(id),
				this.Rate(id),
				this.Sell_button(id),
				this.Balance_currency(id),
				this.Buy_button(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Currency_list $mol_list rows <= currency_list
		 * ```
		 */
		@ $mol_mem
		Currency_list() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.currency_list()
			
			return obj
		}
		
		/**
		 * ```tree
		 * News $hyoo_speculant_app_news model <= model
		 * ```
		 */
		@ $mol_mem
		News() {
			const obj = new this.$.$hyoo_speculant_app_news()
			
			obj.model = () => this.model()
			
			return obj
		}
		
		/**
		 * ```tree
		 * body / <= Body_list
		 * ```
		 */
		body() {
			return [
				this.Body_list()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * foot / <= Foot_list
		 * ```
		 */
		foot() {
			return [
				this.Foot_list()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * buy*?val null
		 * ```
		 */
		@ $mol_mem_key
		buy(id: any, val?: any) {
			if ( val !== undefined ) return val as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Buy_button_icon* $mol_icon_plus
		 * ```
		 */
		@ $mol_mem_key
		Buy_button_icon(id: any) {
			const obj = new this.$.$mol_icon_plus()
			
			return obj
		}
		
		/**
		 * ```tree
		 * sell*?val null
		 * ```
		 */
		@ $mol_mem_key
		sell(id: any, val?: any) {
			if ( val !== undefined ) return val as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Sell_button_icon* $mol_icon_minus
		 * ```
		 */
		@ $mol_mem_key
		Sell_button_icon(id: any) {
			const obj = new this.$.$mol_icon_minus()
			
			return obj
		}
		
		/**
		 * ```tree
		 * rate* \
		 * ```
		 */
		rate(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Currency_diff* $mol_view
		 * ```
		 */
		@ $mol_mem_key
		Currency_diff(id: any) {
			const obj = new this.$.$mol_view()
			
			return obj
		}
		
		/**
		 * ```tree
		 * currency_have* \
		 * ```
		 */
		currency_have(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * currency_icon_link* \
		 * ```
		 */
		currency_icon_link(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Currency_icon* $mol_image2 links / <= currency_icon_link*
		 * ```
		 */
		@ $mol_mem_key
		Currency_icon(id: any) {
			const obj = new this.$.$mol_image2()
			
			obj.links = () => [
				this.currency_icon_link(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * currency_name* \
		 * ```
		 */
		currency_name(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Currency_name* $mol_view sub /
		 * 	<= Currency_icon*
		 * 	<= currency_name*
		 * ```
		 */
		@ $mol_mem_key
		Currency_name(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Currency_icon(id),
				this.currency_name(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * currency_list /
		 * ```
		 */
		currency_list() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Body_list $mol_list rows / <= News
		 * ```
		 */
		@ $mol_mem
		Body_list() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.News()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * date \
		 * ```
		 */
		date() {
			return ""
		}
		
		/**
		 * ```tree
		 * Date $mol_labeler
		 * 	title \Дата
		 * 	content / <= date
		 * ```
		 */
		@ $mol_mem
		Date() {
			const obj = new this.$.$mol_labeler()
			
			obj.title = () => "Дата"
			obj.content = () => [
				this.date()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * balance_total_title \Портфель
		 * ```
		 */
		balance_total_title() {
			return "Портфель"
		}
		
		/**
		 * ```tree
		 * balance_total \
		 * ```
		 */
		balance_total() {
			return ""
		}
		
		/**
		 * ```tree
		 * Balance_total $mol_labeler
		 * 	title <= balance_total_title
		 * 	content / <= balance_total
		 * ```
		 */
		@ $mol_mem
		Balance_total() {
			const obj = new this.$.$mol_labeler()
			
			obj.title = () => this.balance_total_title()
			obj.content = () => [
				this.balance_total()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * balance_cash_title \
		 * ```
		 */
		balance_cash_title() {
			return ""
		}
		
		/**
		 * ```tree
		 * balance_cash \
		 * ```
		 */
		balance_cash() {
			return ""
		}
		
		/**
		 * ```tree
		 * Balance_cash $mol_labeler
		 * 	title <= balance_cash_title
		 * 	content / <= balance_cash
		 * ```
		 */
		@ $mol_mem
		Balance_cash() {
			const obj = new this.$.$mol_labeler()
			
			obj.title = () => this.balance_cash_title()
			obj.content = () => [
				this.balance_cash()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * title_list /
		 * 	<= Date
		 * 	<= Balance_total
		 * 	<= Balance_cash
		 * ```
		 */
		title_list() {
			return [
				this.Date(),
				this.Balance_total(),
				this.Balance_cash()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Title_list $mol_view sub <= title_list
		 * ```
		 */
		@ $mol_mem
		Title_list() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => this.title_list()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Foot_list $mol_list rows /
		 * 	<= Title_list
		 * 	<= Currency_list
		 * ```
		 */
		@ $mol_mem
		Foot_list() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Title_list(),
				this.Currency_list()
			] as readonly any[]
			
			return obj
		}
	}
	
}

